<template>
     <div>
       <v-data-table
            :loading="loading"
            loading-text="LOADING"
            :headers="newheaders"
            :items="table"
            :items-per-page="meta.per_page"
            :hide-default-footer="true"
            class="elevation-0 clickable striped"
            disable-pagination
       >
          
          <template v-slot:item.created_at="{ item }" >
               <span>{{ [ item.created_at, "YYYY-MM-DDTHH:mm:ss.ssssssZ" ] | moment(" D MMMM YYYY, HH:mm") }}</span>
          </template>
          <template v-slot:item.expire_at="{ item }" >
               <span v-if="item.expire_at">{{ [ item.expire_at, "YYYY-MM-DDTHH:mm:ss.ssssssZ" ] | moment(" D MMMM YYYY") }}</span>
          </template>
          <template v-slot:item.pivot.created_at="{ item }">
               <span>{{ [ item.pivot.created_at, "YYYY-MM-DDTHH:mm:ss.ssssssZ" ] | moment(" D MMMM YYYY, HH:mm") }}</span>
          </template>
          <template v-slot:item.updated_at="{ item }">
               <span>{{ [ item.updated_at, "YYYY-MM-DDTHH:mm:ss.ssssssZ" ] | moment(" D MMMM YYYY, HH:mm") }}</span>
          </template>
          <template v-slot:item.issued_on="{ item }">
               <span>{{ [ item.issued_on, "YYYY-MM-DDTHH:mm:ss.ssssssZ" ] | moment(" D MMMM YYYY, HH:mm") }}</span>
          </template>
          <template v-slot:item.used_at="{ item }">
               <span>{{ [ item.used_at, "YYYY-MM-DDTHH:mm:ss.ssssssZ" ] | moment(" D MMMM YYYY, HH:mm") }}</span>
          </template>
          <template v-slot:item.email_verified_at="{ item }">
               <span>{{ item.email_verified_at | moment('calendar') }}</span>
          </template>
          <template v-slot:item.posted_at="{ item }">
               <span>{{ item.posted_at | moment('calendar') }}</span>
          </template>
          <template v-slot:item.amount="{ item }">
               <span>{{item.amount}} {{item.currency_symbol}}</span>
          </template>
          <template v-slot:item.title_description="{ item }">
               <b>{{item.title}}</b><br>
               <small>{{item.description}}</small>
          </template>
          
          <template v-slot:item.percent="{ item }">
               <span>{{item.percent}} %</span>
          </template>
          <template v-slot:item.active="{ item }">
               <v-icon color="green accent-4" v-if="(item.active)">mdi-check-circle</v-icon>
               <v-icon color="red accent-4" v-else>mdi-cancel</v-icon>
          </template>
          <template v-slot:item.playPause="{ item }">
               <v-btn icon color="primary" @click="playPause(item)" v-if="item.status != 'saved'">
                   <v-icon v-if="item.status == 'loading'">mdi-pause</v-icon>
                   <v-icon v-else-if="item.status == 'error'">mdi-play</v-icon>
               </v-btn>
          </template>
          <template v-slot:item.status="{ item }">
               {{ item.status }}
               <span v-if="(item.status)=='saved'"><v-icon color="green accent-4">mdi-content-save</v-icon></span>
               <span v-else-if="(item.status)=='usable'"><v-icon color="green accent-4">mdi-check</v-icon></span>
               <span v-else-if="(item.status)=='added'"><v-icon color="info accent-4">mdi-plus</v-icon></span>
               <span v-else-if="(item.status)=='error'"><v-icon color="red accent-4">mdi-close-octagon</v-icon></span>
               <span v-else-if="(item.status)=='used'"><v-icon color="info accent-4">mdi-check-all</v-icon></span>
               <span v-else>{{item.percent}} % <v-icon color="dark accent-4 " >mdi-loading mdi-spin</v-icon></span>
          </template>
          <template v-slot:item.privacy.user_confirm="{ item }">
               <v-icon color="green accent-4" v-if="(item.privacy.user_confirm)">mdi-check-circle</v-icon>
               <v-icon color="red accent-4" v-else>mdi-cancel</v-icon>
          </template>
          <template v-slot:item.points.level="{ item }">
               <v-badge
                    :color="item.points.level"
                    dot
                    left
               >
                {{item.points.level}}
               </v-badge>
          </template>
          <template v-slot:item.valid_levels="{ item }">
               <v-badge
                    v-for="level in item.valid_levels"
                    :key="level"
                    :color="level"
                    dot
                    left
                    class="mx-1"
               >
               </v-badge>
          </template>
          <template v-slot:item.country="{ item }">
               <v-avatar size="20" :color="item.country" class="mx-auto text-center">
                     <v-img :src="'/img/flags/'+item.country.toLowerCase()+'.png'"></v-img>
               </v-avatar>
          </template>
          <template v-slot:item.discount_amount="{ item }">
               <template v-if="item.type == 'fixed'">
                    <span>{{item.discount_amount}}€</span>
               </template>
               <template v-if="item.type == 'percentage'">
                    <span>{{item.discount_amount}}%</span>
               </template>
          </template>
          <template v-slot:item.avatar="{ item }">
               <div class="my-1">
                    <v-avatar>
                         <img
                         size="60"
                         :src="item.avatar.url.thumbnail"
                         :alt="item.avatar.title"
                         >
                    </v-avatar>
               </div>
          </template>
          <template v-slot:item.view="{ item }">
               <v-btn 
                    small
                    icon
                    color="primary"
                    class="mx-auto"
                    :to="{'name':name, 'params':{'id':item[id]}}"
               >
               <v-icon small >mdi-eye</v-icon>
               </v-btn>
          </template>
          <template v-slot:item.edit="{ item }">
               <v-btn 
                    small
                    icon
                    color="primary"
                    :to="name+item[id]"
               >
               <v-icon small >mdi-pencil</v-icon>
               </v-btn>
          </template>
          <template v-slot:item.download="{ item }">
               <v-btn icon color="primary" @click="playPause(item)" v-if="!(item[download])">
                   <v-icon v-if="item.status == 'loading'">mdi-pause</v-icon>
                   <v-icon v-else-if="item.status == 'error'">mdi-play</v-icon>
               </v-btn>
               <v-btn 
                    small
                    color="primary"
                    target="_blank"
                    :href="item[download]"
                    :disabled="!(item[download])"
                    v-show="(item[download])"
               >
               <v-icon small class="mr-3" >mdi-download</v-icon> DOWNLOAD
               </v-btn>
               
          </template>
        </v-data-table>

        <v-divider></v-divider>

        <div class="text-center py-3">
            <v-pagination
                v-model="meta.current_page"
                :length="meta.last_page"
                :total-visible="5"
                :disabled="loading" 
                @input="page(meta.current_page)"
            ></v-pagination>
        </div>
    </div>
</template>

<script>
export default {
     name:"PaginatedTable",
     props:{
          name:{
               type: String,
               default: "single"
          },
          table: {
               type: Array,
               required: true,
          },
          headers: {
               type: Array,
          },
          edit: {
               type: Boolean,
               default: false
          },
          view: {
               type: Boolean,
               default: false
          },
          download: {
               type: String,
               default: null
          },
          id: {
               type: String,
                default: 'id'
          },
          meta:{
               type: Object,
               required: true
          },
          loading:{
               type: Boolean,
               default: true,
          }
     },
     data: () => ({
         datesKey: ['date_of_birth','start','end'],
         newheaders: [],
         datesTimeKey: ['created_at','expire_at','updated_at','email_verified_at','phone_verified_at','issued_on','posted_at']
     }),
     beforeMount(){
          this.getHeaders()
     },
     methods:{
          getHeaders(){
               if(!this.headers){
                    var keys = Object.keys(this.table);
                    keys.forEach(head => {
                         head  = {text:head, value: head,sortable: false}
                         this.newheaders.push(head)
                    });
               }else{
                    this.newheaders = this.newheaders.concat(this.headers);
               }
               
               if(this.view){
                    this.newheaders.push({text:'commons.view', value: 'view', sortable: false})
               }
               if(this.edit){
                    this.newheaders.push({text:'commons.edit', value: 'edit', sortable: false})
               }
               if(this.download){
                    this.newheaders.push({text:'commons.download', value: 'download', sortable: false})
               }

               if(this.newheaders.length >= 1){
                    this.newheaders.forEach((item) => {
                         item.text = this.$t(item.text);
                    });
               }

               this.newheaders;
          },
          page(page){
               this.meta.current_page = page
               this.$emit('update:meta', this.meta)
               this.$emit('change', this.meta)
          },
          isString(val){
               return typeof val === 'string' || val instanceof String
          },
          isObject(val){
               return (!!val) && (val.constructor === Object);
          },
          isArray(val){
               return (!!val) && (val.constructor === Array)
          },
          isDate(key){
               return this.datesKey.includes(key)
          },
          isDateTime(key){
               return this.datesTimeKey.includes(key)
          },
          playPause(item){
               if(item.status == 'loading'){
                    item.status = 'error'
               }else if(item.status == 'error'){
                    item.status = 'loading'
               }else if(item.status == 'saved'){
                    item.status = 'saved'
               }
               console.log('playOrPause EMIT',item)
               this.$emit('playOrPause', item)
          }
     }    
  }
</script>
<style >
.striped tr:nth-child(even) {background-color:  #0000000D;}
.striped tr:nth-child(odd) {background-color: #FFFFFF; }
</style>