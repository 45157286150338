var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-0 clickable striped",attrs:{"loading":_vm.loading,"loading-text":"LOADING","headers":_vm.newheaders,"items":_vm.table,"items-per-page":_vm.meta.per_page,"hide-default-footer":true,"disable-pagination":""},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("moment")([ item.created_at, "YYYY-MM-DDTHH:mm:ss.ssssssZ" ]," D MMMM YYYY, HH:mm")))])]}},{key:"item.expire_at",fn:function(ref){
var item = ref.item;
return [(item.expire_at)?_c('span',[_vm._v(_vm._s(_vm._f("moment")([ item.expire_at, "YYYY-MM-DDTHH:mm:ss.ssssssZ" ]," D MMMM YYYY")))]):_vm._e()]}},{key:"item.pivot.created_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("moment")([ item.pivot.created_at, "YYYY-MM-DDTHH:mm:ss.ssssssZ" ]," D MMMM YYYY, HH:mm")))])]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("moment")([ item.updated_at, "YYYY-MM-DDTHH:mm:ss.ssssssZ" ]," D MMMM YYYY, HH:mm")))])]}},{key:"item.issued_on",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("moment")([ item.issued_on, "YYYY-MM-DDTHH:mm:ss.ssssssZ" ]," D MMMM YYYY, HH:mm")))])]}},{key:"item.used_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("moment")([ item.used_at, "YYYY-MM-DDTHH:mm:ss.ssssssZ" ]," D MMMM YYYY, HH:mm")))])]}},{key:"item.email_verified_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("moment")(item.email_verified_at,'calendar')))])]}},{key:"item.posted_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("moment")(item.posted_at,'calendar')))])]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.amount)+" "+_vm._s(item.currency_symbol))])]}},{key:"item.title_description",fn:function(ref){
var item = ref.item;
return [_c('b',[_vm._v(_vm._s(item.title))]),_c('br'),_c('small',[_vm._v(_vm._s(item.description))])]}},{key:"item.percent",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.percent)+" %")])]}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [((item.active))?_c('v-icon',{attrs:{"color":"green accent-4"}},[_vm._v("mdi-check-circle")]):_c('v-icon',{attrs:{"color":"red accent-4"}},[_vm._v("mdi-cancel")])]}},{key:"item.playPause",fn:function(ref){
var item = ref.item;
return [(item.status != 'saved')?_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.playPause(item)}}},[(item.status == 'loading')?_c('v-icon',[_vm._v("mdi-pause")]):(item.status == 'error')?_c('v-icon',[_vm._v("mdi-play")]):_vm._e()],1):_vm._e()]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.status)+" "),((item.status)=='saved')?_c('span',[_c('v-icon',{attrs:{"color":"green accent-4"}},[_vm._v("mdi-content-save")])],1):((item.status)=='usable')?_c('span',[_c('v-icon',{attrs:{"color":"green accent-4"}},[_vm._v("mdi-check")])],1):((item.status)=='added')?_c('span',[_c('v-icon',{attrs:{"color":"info accent-4"}},[_vm._v("mdi-plus")])],1):((item.status)=='error')?_c('span',[_c('v-icon',{attrs:{"color":"red accent-4"}},[_vm._v("mdi-close-octagon")])],1):((item.status)=='used')?_c('span',[_c('v-icon',{attrs:{"color":"info accent-4"}},[_vm._v("mdi-check-all")])],1):_c('span',[_vm._v(_vm._s(item.percent)+" % "),_c('v-icon',{attrs:{"color":"dark accent-4 "}},[_vm._v("mdi-loading mdi-spin")])],1)]}},{key:"item.privacy.user_confirm",fn:function(ref){
var item = ref.item;
return [((item.privacy.user_confirm))?_c('v-icon',{attrs:{"color":"green accent-4"}},[_vm._v("mdi-check-circle")]):_c('v-icon',{attrs:{"color":"red accent-4"}},[_vm._v("mdi-cancel")])]}},{key:"item.points.level",fn:function(ref){
var item = ref.item;
return [_c('v-badge',{attrs:{"color":item.points.level,"dot":"","left":""}},[_vm._v(" "+_vm._s(item.points.level)+" ")])]}},{key:"item.valid_levels",fn:function(ref){
var item = ref.item;
return _vm._l((item.valid_levels),function(level){return _c('v-badge',{key:level,staticClass:"mx-1",attrs:{"color":level,"dot":"","left":""}})})}},{key:"item.country",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{staticClass:"mx-auto text-center",attrs:{"size":"20","color":item.country}},[_c('v-img',{attrs:{"src":'/img/flags/'+item.country.toLowerCase()+'.png'}})],1)]}},{key:"item.discount_amount",fn:function(ref){
var item = ref.item;
return [(item.type == 'fixed')?[_c('span',[_vm._v(_vm._s(item.discount_amount)+"€")])]:_vm._e(),(item.type == 'percentage')?[_c('span',[_vm._v(_vm._s(item.discount_amount)+"%")])]:_vm._e()]}},{key:"item.avatar",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"my-1"},[_c('v-avatar',[_c('img',{attrs:{"size":"60","src":item.avatar.url.thumbnail,"alt":item.avatar.title}})])],1)]}},{key:"item.view",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mx-auto",attrs:{"small":"","icon":"","color":"primary","to":{'name':_vm.name, 'params':{'id':item[_vm.id]}}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-eye")])],1)]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","icon":"","color":"primary","to":_vm.name+item[_vm.id]}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1)]}},{key:"item.download",fn:function(ref){
var item = ref.item;
return [(!(item[_vm.download]))?_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.playPause(item)}}},[(item.status == 'loading')?_c('v-icon',[_vm._v("mdi-pause")]):(item.status == 'error')?_c('v-icon',[_vm._v("mdi-play")]):_vm._e()],1):_vm._e(),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:((item[_vm.download])),expression:"(item[download])"}],attrs:{"small":"","color":"primary","target":"_blank","href":item[_vm.download],"disabled":!(item[_vm.download])}},[_c('v-icon',{staticClass:"mr-3",attrs:{"small":""}},[_vm._v("mdi-download")]),_vm._v(" DOWNLOAD ")],1)]}}])}),_c('v-divider'),_c('div',{staticClass:"text-center py-3"},[_c('v-pagination',{attrs:{"length":_vm.meta.last_page,"total-visible":5,"disabled":_vm.loading},on:{"input":function($event){return _vm.page(_vm.meta.current_page)}},model:{value:(_vm.meta.current_page),callback:function ($$v) {_vm.$set(_vm.meta, "current_page", $$v)},expression:"meta.current_page"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }